import Link from 'src/components/Common/Link';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import { NavItemType } from '../types';

/** GLORIOUS teal bar that indicates the item is active/open */
const ActiveItemIndicator = ({ isActive = false, isSelected = false }) => {
  return (
    <span
      className={`${
        isActive || isSelected ? 'opacity-100' : 'opacity-0'
      } absolute top-0 left-0 right-0 h-6 bg-teal-dark transition-opacity duration-200`}
    />
  );
};

const DropdownTriangle = ({ isActive = false }) => {
  return (
    <SvgLoader
      aria-hidden={true}
      className={`${
        isActive ? '' : 'scale-y-flip'
      } flex-shrink-0 fill-current ml-6 w-10 transform duration-200`}
      color={isActive ? 'text-teal-darker' : 'text-white'}
      name="Triangle"
      size={10}
    />
  );
};

const NavItem = ({
  isActive,
  isSelected,
  name,
  currentRef,
  route,
  type,
  onClick,
  analytics,
}: NavItemType) => {
  // determine if PNG site by site name or templateName to update the background color
  const { status } = useAppContext();
  const hoverColor =
    status.site?.name === 'jsspng' || status.route?.templateName === 'JSS PNG App Route'
      ? 'hover:bg-blue-dark/60'
      : 'hover:bg-teal-darker';

  const className = `js-item relative flex items-center text-left p-16 font-bold transition-all duration-300 ${
    isActive ? 'bg-white text-teal-darker' : `text-white focus:bg-teal-darker ${hoverColor}`
  }`;

  if (type === 'button') {
    return (
      <button
        aria-expanded={isActive}
        aria-haspopup={true}
        ref={currentRef}
        className={className}
        onClick={onClick}
      >
        <ActiveItemIndicator isActive={isActive} isSelected={isSelected} />
        {name}
        <DropdownTriangle isActive={isActive} />
      </button>
    );
  }

  return (
    <Link className={className} href={route} onClick={() => track.component(analytics)}>
      <ActiveItemIndicator isActive={isActive} isSelected={isSelected} />
      {name}
    </Link>
  );
};

export { NavItem };
